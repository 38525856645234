
*{
    box-sizing: border-box;
      /* margin:0;
    padding:0 */
}
 /* -----------Top path-------------- */
 .carousel-wrapper{
    width:50%;
    padding:90px 20px 44px 20px;
    float:left
     
 }
.carousel-root.carousel{
    width:100%;

}
 .carousel.carousel-slider{
    max-width: 100%;
}
.slide,.slide.selected{
    background-color: white !important;
    max-width: 100%;
    margin:auto;

}
/* ------------Top picture(big pic)--------- */
.slider.animated img{
    width:55%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}
/* ---------------------------------------- */

/* ------------------arrow button-------------------- */
/* .carousel .control-prev.control-arrow::before{
    border: solid black;
    border-width: 0 3px 3px 0; 
    display:inline-block;
    padding: 4px;
    opacity: 0.4;
    transform: rotate(135deg);
} */
.carousel .control-prev.control-arrow::before{
   content: '';
   border-width: 10px;
   border-style: solid;
   border-color: transparent gray transparent  transparent;
}
/* .carousel .control-next.control-arrow::before{
    border: solid black;
    border-width: 0 3px 3px 0;
    display:inline-block;
    padding: 4px;
    opacity: 1;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
} */
.carousel .control-next.control-arrow::before{
    content: '';
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent  gray;
}

.carousel .control-next.control-arrow:hover,
.carousel .control-prev.control-arrow:hover{
    background:none;
}


/* ---------------------------------------- */

/* ------------------thumbs(small pic)---------------------- */
.thumbs.animated{
    padding:0 20px;
    text-align: center;
}
.carousel .thumb.selected, .carousel .thumb:hover{
    border:2px solid #80a6ea;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}




